<template>
   <div class="container-fluid" style="max-width:700px;">
      <div class="table">
         <table class="table table-borderless table-sm">
            <tr>
               <td style="width: 30%">{{ $trans('origin') }}</td>
               <td>
                  <div class="form-group">
                     <multiselect v-model="search.origin" :options="origins" :multiple="true" :close-on-select="true" :clear-on-select="false" return="value" :preserve-search="true" placeholder="Pick some" label="value" track-by="value" :preselect-first="false">
                        <template slot="selection" slot-scope="{ values, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.map(o => { return o.value }).join(', ') }}</span></template>
                     </multiselect>
                  </div>
               </td>
            </tr>
            <tr v-if="prints.length">
               <td style="width: 30%">{{ $trans('print') }}</td>
               <td>
                  <div class="form-group">
                     <multiselect v-model="search.print" :options="prints" :multiple="true" :close-on-select="true" :clear-on-select="false" return="value" :preserve-search="true" placeholder="Pick some" label="value" track-by="value" :preselect-first="false">
                        <template slot="selection" slot-scope="{ values, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.map(o => { return o.value }).join(', ') }}</span></template>
                     </multiselect>
                  </div>
               </td>
            </tr>
            <tr>
               <td style="width: 30%">{{ $trans('length') }} (cm)</td>
               <td>
                  <div class="row">
                     <div class="col-6 col-md-6">
                        <div class="form-group">
                           <div class="input-group">
                              <div class="input-group-prepend">
                                 <div class="input-group-text">{{ $trans('from') }}:</div>
                              </div>
                              <input type="number" step="10" class="form-control" v-model="search.min_length" />
                           </div>
                        </div>
                     </div>
                     <div class="col-6 col-md-6">
                        <div class="input-group">
                           <div class="input-group-prepend">
                              <div class="input-group-text">{{ $trans('to') }}:</div>
                           </div>
                           <input type="number" step="10" class="form-control" v-model="search.max_length" />
                        </div>
                     </div>
                  </div>
               </td>
            </tr>
            <tr>
               <td style="width: 30%">{{ $trans('width') }} (cm)</td>
               <td>
                  <div class="row">
                     <div class="col-6 col-md-6">
                        <div class="form-group">
                           <div class="input-group">
                              <div class="input-group-prepend">
                                 <div class="input-group-text">{{ $trans('from') }}:</div>
                              </div>
                              <input type="number" step="10" class="form-control" v-model="search.min_width" />
                           </div>
                        </div>
                     </div>
                     <div class="col-6 col-md-6">
                        <div class="input-group">
                           <div class="input-group-prepend">
                              <div class="input-group-text">{{ $trans('to') }}:</div>
                           </div>
                           <input type="number" step="10" class="form-control" v-model="search.max_width" />
                        </div>
                     </div>
                  </div>
               </td>
            </tr>
            <tr>
               <td style="width: 30%">{{ $trans('square_footage') }}</td>
               <td>
                  <div class="row">
                     <div class="col-6 col-md-6">
                        <div class="form-group">
                           <div class="input-group">
                              <div class="input-group-prepend">
                                 <div class="input-group-text">{{ $trans('from') }}:</div>
                              </div>
                              <input type="number" step="10" class="form-control" v-model="search.min_qm" />
                           </div>
                        </div>
                     </div>
                     <div class="col-6 col-md-6">
                        <div class="input-group">
                           <div class="input-group-prepend">
                              <div class="input-group-text">{{ $trans('to') }}:</div>
                           </div>
                           <input type="number" step="0.01" class="form-control" v-model="search.max_qm" />
                        </div>
                     </div>
                  </div>
               </td>
            </tr>
            <tr>
               <td style="width: 30%">{{ $trans('color') }}</td>
                  <td>
                     <div class="form-group">
                     <multiselect v-model="search.color" :options="colors" :multiple="true" :close-on-select="true" :clear-on-select="false" return="value" :preserve-search="true" placeholder="Pick some" label="value" track-by="value" :preselect-first="false">
                        <template slot="selection" slot-scope="{ values, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.map(o => { return o.value }).join(', ') }}</span></template>
                     </multiselect>
                  </div>
               </td>
            </tr>
            <tr>
               <td style="width: 30%">{{ $trans('design') }}</td>
               <td>
                  <div class="form-group">
                     <multiselect v-model="search.design" :options="designs" :multiple="true" :close-on-select="true" :clear-on-select="false" return="value" :preserve-search="true" placeholder="Pick some" label="value" track-by="value" :preselect-first="false">
                        <template slot="selection" slot-scope="{ values, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.map(o => { return o.value }).join(', ') }}</span></template>
                     </multiselect>
                  </div>
               </td>
            </tr>
            <tr>
               <td style="width: 30%">
               {{ $trans('defaultCategories') }}
               </td>
               <td >
                  <div class="form-group">
                     <multiselect v-model="search.customFilters" :options="customFilters.map(o => { return {value:o} })" :multiple="true" :close-on-select="true" :clear-on-select="false" return="value" :preserve-search="true" placeholder="Pick some" label="value" track-by="value" :preselect-first="false">
                        <template slot="selection" slot-scope="{ values, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.map(o => { return o.value }).join(', ') }}</span></template>
                     </multiselect>
                  </div>
               </td>
            </tr>
         </table>
         <br>
         <button class="btn btn-success" @click="doSearch()" v-text="$trans('search')"></button>
      </div>
   </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
export default {
   components: { Multiselect },
   data() {
      return {
         search: {
            min_length : this.$route.query.min_length,
            max_length: this.$route.query.max_length,
            min_width: this.$route.query.min_width,
            max_width: this.$route.query.max_width,
            min_qm: this.$route.query.min_qm,
            max_qm : this.$route.query.max_qm,
            origin : this.$root.origins.filter((o) => {
               if (typeof this.$route.query.origin === 'string') {
                  return this.$route.query.origin === o.value;
               }
               if (typeof this.$route.query.origin === 'object') {
                  return this.$route.query.origin.includes(o.value);
               }
               return false;
            }),
            print: this.$root.allPrints.filter((o) => {
                  if (typeof this.$route.query.print === 'string') {
                     return this.$route.query.print === o.value;
                  }
                  if (typeof this.$route.query.print === 'object') {
                     return this.$route.query.print.includes(o.value);
                  }
                  return false;
               }),
            design: this.$root.designs.filter((o) => {
               if (typeof this.$route.query.design === 'string') {
                  return this.$route.query.design === o.value;
               }
               if (typeof this.$route.query.design === 'object') {
                  return this.$route.query.design.includes(o.value);
               }
               return false;
            }),
            color: this.$root.colors.filter((o) => {
               if (typeof this.$route.query.color === 'string') {
                  return this.$route.query.color === o.value;
               }
               if (typeof this.$route.query.color === 'object') {
                  return this.$route.query.color.includes(o.value);
               }
               return false;
            }),
            customFilters: this.$root.customFilters.filter((o) => {
               if (typeof this.$route.query.customFilters === 'string') {
                  return this.$route.query.customFilters === o;
               }
               if (typeof this.$route.query.customFilters === 'object') {
                  return this.$route.query.customFilters.includes(o);
               }
               return false;
            }).map(o => { return {value: o} }),
         }
      }
   },
   watch: {
      'search.origin': function () {
         this.search.print = null;
      },
   },
   computed: {
      origins() { return this.$root.origins ?? [] },
      designs() { return this.$root.designs ?? [] },
      colors() { return this.$root.colors ?? [] },
      prints() {
         if (!this.search.origin) {
            return [];
         }
         let prints = [];
         this.search.origin.forEach(o => {
            prints = [...prints, ...this.$root.prints[o.value]]
               .sort((a,b) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0))
         })
         return prints;
      },
      customFilters(){ return this.$root.customFilters ?? [] }
   },
   methods: {
      doSearch() {
         let thisSearch = this.search;
         // fix multiple selector returned data
         if (thisSearch.origin) {thisSearch.origin = thisSearch.origin.map(o => {return o.value;})}
         if (thisSearch.design) {thisSearch.design = thisSearch.design.map(o => {return o.value;})}
         if (thisSearch.color) {thisSearch.color = thisSearch.color.map(o => {return o.value;})}
         if (thisSearch.print) {thisSearch.print = thisSearch.print.map(o => {return o.value;})}
         if (thisSearch.customFilters) {thisSearch.customFilters = thisSearch.customFilters.map(o => {return o.value;})}

         this.$root.doSearch(thisSearch);
      }
   },

}
</script>
