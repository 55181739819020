<template>
   <div class="top-filters-container">
      <div class="row" style="min-width:300px;">
         <div class="col-md-6" v-if="sortingEnabled">
            <p style="margin:0; padding:0" class="small">{{ $trans('orderBy')}}</p>
            <select class="form-control select-filter" v-model="sort" @change="addFilter">
               <option value="-rand-">-</option>
               <option value="p.name|asc">{{$trans('name')}} A-Z</option>
               <option value="p.name|desc">{{$trans('name')}} Z-A</option>
               <option value="p.created_at|asc">{{$trans('createdAt')}} &lt;</option>
               <option value="p.created_at|desc">{{$trans('createdAt')}} &gt;</option>
               <option value="a_width.value|asc">{{$trans('width')}} &lt;</option>
               <option value="a_width.value|desc">{{$trans('width')}} &gt;</option>
               <option value="a_length.value|asc">{{$trans('length')}} &lt;</option>
               <option value="a_length.value|desc">{{$trans('length')}} &gt;</option>
               <option value="a_qm.value|asc">{{$trans('square_footage')}} &lt;</option>
               <option value="a_qm.value|desc">{{$trans('square_footage')}} &gt;</option>
               <option value="p.erp_id|asc">{{$trans('ID')}} &lt;</option>
               <option value="p.erp_id|desc">{{$trans('ID')}} &gt;</option>
            </select>
         </div>
         <div class="col-md-6">
            <p style="margin:0; padding:0" class="small">{{ $trans('productsShown')}}</p>
            <select class="form-control select-per-page" v-model="per_page" @change="addFilter">
               <option value="16">16 {{ $trans('perPage') }}</option>
               <option value="32">32 {{ $trans('perPage') }}</option>
               <option value="52">52 {{ $trans('perPage') }}</option>
               <option value="100">100 {{ $trans('perPage') }}</option>
               <option value="200">200 {{ $trans('perPage') }}</option>
            </select>
         </div>
      </div>
   </div>
</template>
<script>
export default {
   mounted() {
      if (this.$route.query.sort !== undefined) {
         this.sort = this.$route.query.sort
      }
      if (this.$route.query.per_page !== undefined) {
         this.per_page = this.$route.query.per_page
      }
   },
   props : {
      latest: { required:false, default: false },
      sortingEnabled: { required: false, default: true }
   },
   data() {
      return {
         sort: '-rand-',
         per_page: process.env.VUE_APP_DEFAULT_PAGINATE
      }
   },
   methods: {
      addFilter() {
         let filter = {};
         if (this.sort) { filter.sort = this.sort }
         if (this.per_page) { filter.per_page = this.per_page }
         this.$root.addFilter(filter, this.latest);
      }
   }
}
</script>