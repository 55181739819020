window.axios = require('axios').default;
window.axios.defaults.baseURL = process.env.VUE_APP_AXIOS_BASE_URL;

import Vue from 'vue'
import 'vue-multiselect/dist/vue-multiselect.min.css'
Vue.config.productionTip = false
Vue.config.devtools = true;
import VueRouter from 'vue-router'
import routes from './routes'
Vue.use(VueRouter)
const router = new VueRouter({
  mode: 'history',
  routes,
});

import moment from 'moment'
Vue.prototype.moment = moment

import localeMixin from './locale'
Vue.mixin(localeMixin);
import Multiselect from 'vue-multiselect'

window.vm = new Vue({
  components: { Multiselect },
  router,
  data() {
    return {
      loading: true,
      selectedProducts: [],
      products: [],
      pagination : null,
      origins: [],
      designs: [],
      colors: [],
      colors2: [],
      prints: [],
      allPrints: [],
      customFilters:[],
      user: {locale:'de'},
      cart: null,
      search: {
        string: ''
      },
      token: null,
      latestProductId: null,
    }
  },
  mounted() {
    window.localStorage.setItem('locale', 'de');
    if (this.$route.fullPath !== '/login' && this.$route.fullPath !== '/logout' && this.$route.fullPath !== '/password-lost')
      this.getBootData()

    if (!this.checkAuth()) {
      this.$router.push('/login').catch(()=>{});
    }
  },
  created() {
    if (this.$route.query.string) {
      this.search.string = this.$route.query.string;
      this.fetchData();
    }
  },
  computed: {
    cartItemsLength() {
      return this.cart? (this.cart.items ? this.cart.items.length : 0) : 0
    },
    logo() {
      return (this.user && this.user.logo) 
        ? process.env.VUE_APP_LOGOS_BASE_URL+'/'+this.user.logo 
        : '/images/logo.gif';
    }
  },
  methods: {
    resetTempData() {
      this.selectedProducts = [];
    },
    placeOrder() {
      window.axios.post('/place-order', { }, {headers: {Authentication:`bearer ${window.localStorage.getItem('token')}`}})
      .then(() => {
        this.cart = null
        window.$.notify({ message: this.$trans('alert_orderCreated', this.user.locale) }, { type: 'success', delay: 2000 });
        // this.$router.push('/').catch(() => {})
      })
    },
    addProductsToApi(userid) {
      window.axios.post('/users/'+userid+'/add-products-to-api', {
          product_ids : this.selectedProducts
        },{
          headers: {Authentication:`bearer ${window.localStorage.getItem('token')}`}
      })
      .then(() => {
        window.$.notify({ message: this.$trans('alert_addedToApi', this.user.locale) }, { type: 'success', delay: 2000 });
        this.selectedProducts = [];
      })
      .catch(err => {window.$.notify({ message: err }, { type: 'danger', delay: 2000 });})
    },
    addToCartSelected() {
      this.addToCart(this.selectedProducts);
    },
    toggleSelectAllProducts(selected, products) {
      if (selected) {
        this.selectedProducts = this.selectedProducts
          .concat(Object.values(products).map(o => o.id))
          .filter((v, i, a) => a.indexOf(v) === i)
      } else {
        this.selectedProducts = this.selectedProducts.filter(o => {
          return !Object.values(products).map(o => o.id).includes(o)
        })
      }
    },
    emptyCart() {
      window.axios.post('/clear-cart', {}, {
        headers: {Authentication:`bearer ${window.localStorage.getItem('token')}`}})
      .then(() => {
        this.cart = null;
        window.$.notify({ message: this.$trans('alert_cartDoEmpty', this.user.locale) }, { type: 'success', delay: 2000 });
        this.selectedProducts = [];
      })
    },
    addToCart(product_id) {
      window.axios.post('/add-to-cart', { 
        product_id: ((typeof product_id) === 'object' ? null : product_id),
        product_ids: ((typeof product_id) === 'object' ? product_id : null),
        user_id : this.user.id
      }, {headers: {Authentication:`bearer ${window.localStorage.getItem('token')}`}})
      .then(res => {
        this.cart = res.data
        window.$.notify({ message: this.$trans('alert_addedToCart', this.user.locale) }, { type: 'success', delay: 2000 });
        this.selectedProducts = [];
      })
    },
    removeFromCart(product_id) {
      window.axios.post('/remove-from-cart', { 
        product_id: product_id,
        user_id : this.user.id
      }, {headers: {Authentication:`bearer ${window.localStorage.getItem('token')}`}})
      .then(res => {
        this.cart = res.data
      })
      .catch(err => {window.$.notify({ message: err }, { type: 'danger', delay: 2000 });})
    },
    exportSelectedProductsXLSX() {
      window.open(process.env.VUE_APP_AXIOS_BASE_URL+'/products-xlsx?tk='+window.localStorage.getItem('token')+'&product_ids='+this.selectedProducts.join('x'));
    },
    exportAllProductsXLSX() {
      window.open(process.env.VUE_APP_AXIOS_BASE_URL+'/products-xlsx?tk='+window.localStorage.getItem('token')+'&export_all=1');
    },
    exportCartProductsXLSX() {
      window.open(process.env.VUE_APP_AXIOS_BASE_URL+'/cart-xlsx?tk='+window.localStorage.getItem('token'));
    },
    bulkDeleteSelected() {
      window.axios.post('/products-bulk-delete', { 
        products: this.selectedProducts
      }, {headers: {Authentication:`bearer ${window.localStorage.getItem('token')}`}})
      .then(() => {
        this.selectedProducts = [];
        window.location.reload();
      })
      .catch(err => {window.$.notify({ message: err }, { type: 'danger', delay: 2000 });})
    },
    getBootData(token = null) {
      if (token === null) { token = window.localStorage.getItem('token'); }
      this.loading = true
      window.axios.get('/boot?dt='+new Date().getTime(), {headers: {Authentication:`bearer ${window.localStorage.getItem('token')}`}})
        .then(res => {
          this.origins = res.data.search.origins
          this.designs = res.data.search.designs
          this.colors = res.data.search.colors
          this.prints = res.data.search.prints
          this.allPrints = res.data.search.allPrints,
          this.customFilters = res.data.search.customFilters
          this.user = res.data.user
          this.cart = res.data.cart
          this.logos = res.data.logos
          this.loading = false
          window.localStorage.setItem('locale', (res.data.user.locale ? res.data.user.locale : 'de'))
          // set user locale
        })
        .catch(err => {return err;})
    },
    setLocale(locale) {
      if (typeof locale === 'object') {  locale = locale.lang }
      if (this.token != null) {
        window.axios.post('/users/current', {
          locale : locale
        }, {headers: {Authentication:`bearer ${window.localStorage.getItem('token')}`}})
          .then(res => {
            this.user = res.data
            window.localStorage.setItem('locale', locale)
            window.location.reload();
          })
          .catch(err => {window.$.notify({ message: err }, { type: 'danger', delay: 2000 });})
      } else {
        this.user.locale = locale
        window.localStorage.setItem('locale', locale)
      }
    },
    getCartData() {
      this.loading = true
      window.axios.get('/cart', {headers: {Authentication:`bearer ${window.localStorage.getItem('token')}`}})
        .then(res => {
          this.origins = res.data.data.origins
          this.designs = res.data.data.designs
          this.colors = res.data.data.colors
          this.colors2 = res.data.data.colors2
          this.loading = false
        })
        .catch(err => {window.$.notify({ message: err }, { type: 'danger', delay: 2000 });})
    },
    logout() {
      this.search = {
        string: ''
      };
      window.axios.post('/logout', {userid: this.user.id}, {headers: {Authentication:`bearer ${window.localStorage.getItem('token')}`}})
      .catch(err => {window.$.notify({ message: err }, { type: 'danger', delay: 2000 });})
      window.localStorage.removeItem('token');
      this.token = null;
      this.user = {locale:'en'};
      this.cart = null;
      this.search.string = ''
      this.$router.push('/login').catch(() => {})
    },
    checkAuth() {
      if (window.localStorage.getItem('token')) {
        this.token = window.localStorage.getItem('token');
        return true;
      } else {
        return false;
      }
    },
    doDesktopSearch() {
      this.search.string = this.$refs['search-desktop'].value;
      this.$router.push({path:'/list'}).catch(()=>{})
      this.fetchData()
    },
    doMobileSearch() {
      this.search.string = this.$refs['search-mobile'].value;
      this.$router.push({path:'/list'}).catch(()=>{})
      this.fetchData()
    },
    fetchData(latest = false) {
      this.loading = true;
      let url = latest ? '/products-latest' : '/products-search';
      let params = {}
      if (this.search.string !== undefined && this.search.string.length > 0) {
        params = { string: this.search.string, page: this.$route.query.page ? this.$route.query.page : 1 }
      } else {
        params = {  ...this.$route.query, ...this.search };
        delete params.string
      }
      // const params = {  ...this.$route.query, ...this.search };
      // if (params.string === '') { delete params.string }
      if (!params.page) { params.page = 1; }
      this.$router.push({query: params}).catch(()=>{});
      window.axios.get(url, {
        params : {...params, ...{ dt: new Date().getTime() }},
        headers: {Authentication:`bearer ${window.localStorage.getItem('token')}`}
      })
      .then(res => {
        this.products = res.data.data;
        this.pagination = this.getPagination(res.data)
        this.loading = false;
        setTimeout(() => {
          if (this.latestProductId !== null &&  
            document.getElementById(this.latestProductId) !== null) {
               document.getElementById(this.latestProductId).scrollIntoView()
          }
        },200);
      })
      .catch(err => {window.$.notify({ message: err }, { type: 'danger', delay: 2000 });})
    },
    doSearch(search) {
      const s = [];
      for (const [key, value] of Object.entries(search)) {
        if (value !== undefined && value !== null) {
          s[key] = value;
        }
      }
      this.search = s
      this.$router.push({path:'/list', query:s}).catch(()=>{})
    },
    getLatest() {
      this.search = {}
      if (!this.$route.query.page)
        this.$router.push({query:{page:1}}).catch(()=>{})
      this.fetchData(true);
    },
    doLogin(email, pass) {
      this.loading = true;
      window.axios.post('/login', {
        email: email,
        pass: pass
      }).then(res => {
        this.loading = false;
        if (res.data === 'KO') {
          window.$.notify({ message: this.$trans('alert_loginError', this.user.locale) }, { type: 'danger', delay: 2000 });
        } else {
          window.localStorage.setItem('token', res.data);
          this.token = res.data;
          window.$.notify({ message: this.$trans('alert_loginSuccess', this.user.locale) }, { type: 'success', delay: 2000 });
          this.$router.push('/search').catch(()=>{});
          this.getBootData(res.data)
        }
      })
      .catch(err => {window.$.notify({ message: err }, { type: 'danger', delay: 2000 });})
    },
    goToPage(page, latest = false) {
      this.$router.replace({query: {...this.$route.query, ...{ page: page } } })
      console.log(this.$route.query)
      console.log(latest)
      //this.fetchData(latest)
    },
    addFilter(filter, latest = false) {
      this.$router.replace({query: { ...this.$route.query, ...filter }})
      this.fetchData(latest)
    },
    getPagination(data) {
      return {
        lastPage : data.last_page,
        perPage: data.per_page,
        totalProducts: data.total,
        links: data.links ? Object.values(data.links).map(o => {
          return o.label
        }) : null
      }
    },
    toggleMobileMenu() {
      if (this.$refs['navbarToggle'].style.display == 'block')
        this.$refs['navbarToggle'].style.display = 'none'
      else
        this.$refs['navbarToggle'].style.display = 'block'
    }
  }
}).$mount('#borhani-app')
