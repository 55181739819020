<template>
   <div v-if="pagination.links">
      <nav aria-label="Page navigation example">
         <ul class="pagination">
            <li class="page-item">
               <a class="page-link" href="javascript:;" @click="goToPrevPage" aria-label="Previous">
               <span aria-hidden="true">&laquo;</span>
               <span class="sr-only"></span>
               </a>
            </li>
            <li class="page-item" v-for="(link,k) in pagination.links.filter(o => { return !o.includes('Next') && !o.includes('Previous') })" :key="k" :class="{'active' : $route.query.page !== undefined ? $route.query.page === link : false}">
               <a class="page-link" href="javascript:;" @click="goToPage(link)" >{{link}}</a>
            </li>
            <li class="page-item">
               <a class="page-link" href="javascript:;" @click="goToNextPage" aria-label="Next">
               <span aria-hidden="true">&raquo;</span>
               <span class="sr-only"></span>
               </a>
            </li>
         </ul>
      </nav>
   </div>
</template>
<script>
export default {
   props : {
      pagination: { required:true }
   },
   methods: {
      goToPage(page) {
         if (page === '...') { return ; }
         this.$emit('gotopage', page)
      },
      goToPrevPage() {
         console.log('prev')
         this.goToPage(+this.$route.query.page-1);
      },
      goToNextPage() {
         console.log('next')
         this.goToPage(+this.$route.query.page+1);
      }
   }
}
</script>