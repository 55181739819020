<template>
   <div class="">
      <input type="text" class="form-control" v-model="search" :placeholder="$trans('search')+'...'" @keyup.enter="doSearchAdmin">
   </div>
</template>
<script>
export default{
   data() {
      return {
         search: this.$route.query.search ?? ''
      }
   },
   methods: {
      doSearchAdmin() {
         this.$router.push({query: {...this.$route.query, ...{ search: this.search } }})
         this.$emit('get-data')
      }
   }
}
</script>
