<template>
   <div class="container">
      <div class="row">
         <div class="col-md-12">
            <div class="table-container" style="max-width:700px; margin:auto">
               <table class="table table-striped table-condensed">
                  <thead>
                     <tr>
                        <th>{{ $trans('name', $root.user.locale) }}</th>
                        <th style="text-align:right">{{ $trans('price_per_qm', $root.user.locale) }}</th>
                        <th style="text-align:right">{{ $trans('knots', $root.user.locale) }}</th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr v-for="(data, name) in nameData" :key="name">
                        <td>{{ name }}</td>
                        <td style="text-align:right;">
                           <input style="max-width:200px; margin-left:auto" class="form-control" type="number" step="0.01" v-model="nameData[name].price" />
                        </td>
                        <td style="text-align:right;">
                           <input style="max-width:200px; margin-left:auto" class="form-control" type="text" v-model="nameData[name].knots" />
                        </td>
                     </tr>
                  </tbody>
               </table>
               <button class="btn btn-success" @click="save" style="float:right">{{ $trans('save', $root.user.locale) }}</button>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
export default {
   mounted() {
      this.getData();
   },
   data() {
      return {
         nameData: []
      }
   },
   methods: {
      getData() {
         window.axios.get('/name-data', {
            headers: {Authentication:`bearer ${window.localStorage.getItem('token')}`}
         })
         .then(res => {
            this.nameData = res.data
         })
         .catch(err => {window.$.notify({ message: err }, { type: 'danger', delay: 2000 });})
      },
      save() {
         window.axios.post('/name-data', { nameData: this.nameData }, {
            headers: {Authentication:`bearer ${window.localStorage.getItem('token')}`}
         })
         .then(res => {
            this.nameData = res.data
         })
         .catch(err => {window.$.notify({ message: err }, { type: 'danger', delay: 2000 });})
      }
   }
}
</script>
