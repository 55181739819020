<template>
   <div class="container-fluid">
      <div class="row">
         <div class="col-md-6">
            <adminPaginator :pagination="pagination" @gotopage="goToPage"></adminPaginator>
         </div>
         <div class="col-md-6">
            <searcher @get-data="getData"></searcher>
         </div>
      </div>
      <div class="table-responsive">
         <vuetable ref="vuetable"
         :api-mode="false"
         :data="orders"
         :fields="fields"
         :multi-sort="false"
         @vuetable:row-clicked="clicked"
         ></vuetable>
      </div>
      <adminPaginator :pagination="pagination" @gotopage="goToPage"></adminPaginator>
   </div>
</template>
<script>
import Vuetable from 'vuetable-2'
import AdminPaginator from './AdminPaginator'
import Searcher from './../Searcher'
import moment from 'moment'
export default {
   components: { Vuetable, AdminPaginator, Searcher },
   mounted() {
      if (this.$route.query.page) {
         this.page = this.$route.query.page
      } else {
         this.$router.push({query: {page: 1}}).catch(()=>{})
      }
      this.getData()
   },
   data() {
      return {
         pagination: {},
         page: 1,
         orders: [],
         fields: [
            {
               name: 'id',
               title: 'ID',
               sortField: 'id'
            },
            {
               name: 'items_count',
               title: 'Items',
               sortField: 'items_count'
            },
            {
               name: 'user_companyname',
               title: 'User Name',
               sortField: 'user_companyname'
            },
            {
               name: 'user_email',
               title: 'User Email',
               sortField: 'user_email'
            },
            {
               name: 'created_at',
               title: 'Date',
               sortField: 'created_at',
               formatter (value) {
                  return moment(value).format('yyyy-MM-DD hh:mm')
               }
            }
         ]
      }
   },
   methods: {
      getData() {
         window.axios.get('/orders', {
            params: {
               page: this.$route.query.page,
               string: this.$route.query.search ?? null
            },
            headers: {Authentication:`bearer ${window.localStorage.getItem('token')}`}
         })
         .then(res => {
            this.orders = res.data.data
            this.pagination = this.$root.getPagination(res.data)
         })
         .catch(err => {window.$.notify({ message: err }, { type: 'danger', delay: 2000 });})
      },
      goToPage(page) {
         this.$router.push({query:{page:page}})
         this.getData()
      },
      clicked(data) {
         this.$router.push('/admin-orders/'+data.data.id);
      }
   }
}
</script>
