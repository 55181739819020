<template>
   <div class="container" style="max-width:700px;">
      <div class="table">
         <table class="table table-borderless table-sm">
            <tr>
               <td style="width: 30%">Email</td>
               <td>
                  <div class="form-group">
                     <input type="text" class="form-control" name="email" v-model="email" @keydown.enter="resetPassword" />
                  </div>
               </td>
            </tr>
         </table>      
         <router-link to="/login" v-text="'Standard Login'"></router-link>
         <br>
         <br>
         <button class="btn btn-success" @click="resetPassword" v-text="$trans('sendEmail')"></button>
         <br>
      </div>
   </div>
</template>
<script>
export default {
   mounted() {
      this.$root.loading = false
   },
   data() {
      return {
         email: null,
      }
   },
   methods: {
      resetPassword() {
         window.axios.post('/reset-password', 
            {email: this.email},
            {headers: {Authentication:`bearer ${window.localStorage.getItem('token')}`}})
         .then(res => {
            if (res.data === 'OK') {
               window.$.notify({ message: this.$trans('alert_passwordSent', this.$root.user.locale) }, { type: 'success', delay: 2000 });
            } else {
               window.$.notify({ message: this.$trans('alert_wrongAddress', this.$root.user.locale) }, { type: 'danger', delay: 2000 });
            }
            this.$router.push('/login')
         })
         .catch(err => {window.$.notify({ message: err }, { type: 'danger', delay: 2000 });})
      }
   }
}
</script>