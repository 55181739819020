
<template>
   <div class="container-fluid">
      <div class="row">
         <div class="col">
            <button class="btn btn-success btn-xs" style="margin-bottom:10px; float:right; height:30px; line-height:0" @click="$router.push('/admin-users/new')">{{ $trans('new', $root.user.locale) }}</button>
         </div>
      </div>
      <div class="row">
         <div class="col-md-6">
            <adminPaginator :pagination="pagination" @gotopage="goToPage"></adminPaginator>
         </div>
         <div class="col-md-6">
            <searcher @get-data="getData"></searcher>
         </div>
      </div>
      <div class="table-responsive">
         <vuetable ref="vuetable"
         :api-mode="false"
         :fields="fields"
         :multi-sort="false"
         @vuetable:row-clicked="clicked"
         :data-manager="dataManager"
         :sort-order="[{
            sortField: 'default',
            direction: ''
         }]"
         ></vuetable>
      </div>
      <adminPaginator :pagination="pagination" @gotopage="goToPage"></adminPaginator>
   </div>
</template>
<script>
import Vuetable from 'vuetable-2'
import AdminPaginator from './AdminPaginator'
import Searcher from './../Searcher'
export default {
   components: { Vuetable, AdminPaginator, Searcher },
   mounted() {
      if (this.$route.query.page) {
         this.page = this.$route.query.page
      } else {
         this.$router.replace({query: {page: 1, orderby:'erp_id-desc'}}).catch(()=>{})
      }
      this.getData()
   },
   data() {
      return {
         pagination: {},
         page: 1,
         users: [],
         fields: [
            {
               name: 'id',
               title: 'ID',
               sortField: 'id'
            },
            {
               name: 'erp_id',
               title: 'ERP ID',
               sortField: 'erp_id'
            },
            {
               name: 'has_api',
               title: 'API',
               sortField: 'has_api',
               formatter: (value) => {
                  return value ? '✓' : ''
               }
            },
            {
               name: 'name',
               title: 'Name',
               sortField: 'name'
            },
            {
               name: 'companyname',
               title: 'Companyname',
               sortField: 'companyname'
            },
            {
               name: 'email',
               title: 'Email',
               sortField: 'email'
            }
         ]
      }
   },
   methods: {
      async getData() {
         window.axios.get('/users', {
            params: {
               page: this.$route.query.page,
               string: this.$route.query.search ?? null,
               orderby: this.$route.query.orderby ?? 'erp_id-desc'
            },
            headers: {Authentication:`bearer ${window.localStorage.getItem('token')}`}
         })
         .then(res => {
            this.users = res.data.data
            this.pagination = this.$root.getPagination(res.data)
            if (this.$refs.vuetable !== undefined) {
               this.$refs.vuetable.reload()
            }
         })
         .catch(err => {
            window.$.notify({ message: err }, { type: 'danger', delay: 2000 });
            this.$router.push('/admin-users');
         })
      },
      goToPage(page) {
         this.$router.replace({query:{page:page, orderby:this.$route.query.orderby}}).catch(()=>{})
      },
      clicked(data) {
         this.$router.push('/admin-users/'+data.data.id);
      },
      dataManager(sortOrder, pagination) {
         if (sortOrder.length > 0) {
            let newsortorder = sortOrder[0].sortField+'-'+sortOrder[0].direction
            if (newsortorder !== 'default-' && newsortorder !== this.$route.query.orderby ) {
               let newsortorder = sortOrder[0].sortField+'-'+sortOrder[0].direction
               this.$router.replace({query:{page:this.$route.query.page, orderby: newsortorder}}) ;
            }
         }
         return {
            pagination: pagination,
            data: this.users
         };
      }
   }
}
</script>
