<template>
   <loop :latest="true" :sortingEnabled="false" />
</template>
<script>
import Loop from './Loop'

export default {
   components: { Loop },
   mounted() {
      this.$root.getLatest()
   },
}
</script>