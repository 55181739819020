<template>
   <div class="container-fluid">
      {{ $trans('productsInCart').replace('[x]',$root.cartItemsLength) }}
      <br>
      <div v-if="cart && cart.items.length > 0">
         <br>
         <div style="text-align:center">
            <div class="row">
               <div class="col-md-2" style="text-align:left">
                  <button class="btn btn-warning" style="color:white;" @click="exportCSV"><i class="fa fa-download"></i> Export</button>
               </div>
               <div class="col-md-8">
                  <a href="javascript:;" @click="placeOrder" class="btn btn-success" style="width:80%;"> <i class="fas fa-shopping-cart"></i>&nbsp;{{ $trans('placeOrder') }}</a>
               </div>
               <div class="col-md-2" style="text-align:right">
                  <a href="javascript:;" @click="emptyCart" class="btn"> <i class="fas fa-times"></i>&nbsp;{{ $trans('clearCart') }}</a>
               </div>
            </div>
         </div>
         <br>
         <div class="card">
            <div class="table" style="margin-bottom: 0px;"><table class="table table-borderless table-sm" style="margin-bottom: 0px;">
               <tr v-for="item in cart.items" :key="item.id">
                  <td>
                     <img style="cursor:pointer; max-height:120px;" @click="$router.push('/products/'+item.product.id)" :src="item.product.all_icons[0]" />
                  </td>
                  <td>
                     <h6 style="cursor:pointer;" @click="$router.push('/products/'+item.product.id)"><u>{{ item.product.name }} [{{ item.product.erp_id }}]</u>
                        <span v-if="item.product.status === 'unavailable'" style="background: #f6c873; padding: 0px 6px; margin-left: 10px;"> {{ $trans('unavailable') }} </span>
                     </h6>
                     <div style="font-size:12px; line-height:16px;">
                        <div v-if="item.product.formatted_attributes['origin']">
                           <span>{{ $trans('origin') }}:</span>
                           <span style="font-weight:bold;">  {{ item.product.formatted_attributes.origin }}</span>
                        </div>
                        <div v-if="item.product.formatted_attributes['length']">
                           <span>{{ $trans('length') }}:</span>
                           <span style="font-weight:bold;">  {{ item.product.formatted_attributes.length }}</span>
                        </div>
                        <div v-if="item.product.formatted_attributes['width']">
                           <span>{{ $trans('width') }}:</span>
                           <span style="font-weight:bold;">  {{ item.product.formatted_attributes.width }}</span>
                        </div>
                        <div v-if="item.product.formatted_attributes['qm']">
                           <span>Area in m2:</span>
                           <span style="font-weight:bold;">  {{ item.product.formatted_attributes.qm }}</span>
                        </div>
                        <div v-if="item.product.formatted_attributes['color']">
                           <span>{{ $trans('color') }}:</span>
                           <span style="font-weight:bold;">  {{ item.product.formatted_attributes.color.join(', ') }}</span>
                        </div>
                        <div v-if="item.product.formatted_attributes['color2']">
                           <span>{{ $trans('color2') }}:</span>
                           <span style="font-weight:bold;">  {{ item.product.formatted_attributes.color2.join(', ') }}</span>
                        </div>
                        <div v-if="item.product.formatted_attributes['design']">
                           <span>{{ $trans('print') }}:</span>
                           <span style="font-weight:bold;">  {{ item.product.formatted_attributes.design }}</span>
                        </div>
                     </div>
                  </td>
                  <td style="width:100px">
                     <a href="javascript:;" @click="removeFromCart(item.product.id)" class="btn btn-info" style="border:1px solid #542E0F; background-color: #542E0F!important; color:white!important;"> <i class="fas fa-shopping-cart"></i>{{ $trans('remove') }}</a>
                  </td>
               </tr>

            </table></div>
         </div>
         <br>
         <div style="text-align:center">
            <div class="row">
               <div class="col-md-2" style="text-align:left">
                  <button class="btn btn-warning" style="color:white;" @click="exportCSV"><i class="fa fa-download"></i> Export</button>
               </div>
               <div class="col-md-8">
                  <a href="javascript:;" @click="placeOrder" class="btn btn-success" style="width:80%;"> <i class="fas fa-shopping-cart"></i>&nbsp;{{ $trans('placeOrder') }}</a>
               </div>
               <div class="col-md-2" style="text-align:right">
                  <a href="javascript:;" @click="emptyCart" class="btn"> <i class="fas fa-times"></i>&nbsp;{{ $trans('clearCart') }}</a>
               </div>
            </div>
         </div>
         <br>
         <br>
         <br>
      </div>
   </div>
</template>
<script>
export default {
   computed: {
      cart() {
         return this.$root.cart
      }
   },
   methods: {
      getSubtitle(product) {
         let arr = [];
         if (product.formatted_attributes['origin']) { arr.push(product.formatted_attributes['origin']) }
         if (product.formatted_attributes['design']) { arr.push(product.formatted_attributes['design']) }
         return arr.join(' - ')
      },
      removeFromCart(product_id) {
         this.$root.removeFromCart(product_id)
      },
      placeOrder() {
         if (this.cart.items.filter(o => {
            return o.product.status === 'unavailable'
         }).length > 0) {
            window.$.notify({ message: this.$trans('alert_productsNotAvailable', this.$root.user.locale) }, { type: 'success', delay: 2000 });
         } else {
            this.$root.placeOrder()
         }
      },
      emptyCart() {
         this.$root.emptyCart()
      },
      exportCSV() {
         this.$root.exportCartProductsXLSX();
      }
   }
}
</script>
