<template>
   <div>
      <div class="container-fluid" style="margin-bottom:20px;">
         <transition name="slide">
            <search ref="search" v-if="showSearch"></search>
         </transition>
         <div class="filters-bar" :class="{'filters-bar-active':hasSearch}" @click="showSearch = !showSearch">
            <b v-if="hasSearch" style="backgru">{{$trans('filtersEnabled')}}</b>
            <b v-else>{{$trans('filters')}}</b>
            &nbsp;
            <i class="fas fa-angle-down" v-show="!showSearch"></i>
            <i class="fas fa-angle-up" v-show="showSearch"></i>
         </div>
      </div>
      <loop :latest="latest"></loop>
   </div>
</template>
<script>
import Loop from './Loop'
import Search from './Search'
export default {
   props : { latest: { required:false, default: false} },
   components: {Loop, Search},
   data() {
      return {
         showSearch: false
      }
   },
   mounted() {
      this.$root.fetchData();
   },
   computed: {
      hasSearch() {
         return this.queryParamIsNotEmpty('color') ||
            this.queryParamIsNotEmpty('customFilters') ||
            this.queryParamIsNotEmpty('design') ||
            this.queryParamIsNotEmpty('max_length') ||
            this.queryParamIsNotEmpty('max_qm') ||
            this.queryParamIsNotEmpty('max_width') ||
            this.queryParamIsNotEmpty('min_length') ||
            this.queryParamIsNotEmpty('min_qm') ||
            this.queryParamIsNotEmpty('min_width') ||
            this.queryParamIsNotEmpty('origin') ||
            this.queryParamIsNotEmpty('print');
      },
   },
   methods: {
      queryParamIsNotEmpty(something) {
         return this.$route.query[something] !== undefined &&
         (
            Array.isArray(this.$route.query[something])
               ? (this.$route.query[something].length > 0)
               : (this.$route.query[something] !== false)
         );
      }
   }
}
</script>
<style scoped>
.slide-enter-active {
   -moz-transition-duration: 0.3s;
   -webkit-transition-duration: 0.3s;
   -o-transition-duration: 0.3s;
   transition-duration: 0.3s;
   -moz-transition-timing-function: ease-in;
   -webkit-transition-timing-function: ease-in;
   -o-transition-timing-function: ease-in;
   transition-timing-function: ease-in;
}

.slide-leave-active {
   -moz-transition-duration: 0.3s;
   -webkit-transition-duration: 0.3s;
   -o-transition-duration: 0.3s;
   transition-duration: 0.3s;
   -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
   max-height: 100px;
   overflow: hidden;
}

.slide-enter, .slide-leave-to {
   overflow: hidden;
   max-height: 0;
}

.filters-bar {
   border: 2px solid #4c1a20;
   text-align:center;
   cursor:pointer
}

.filters-bar-active {
   background-color: #8c702c;
   color: white;
}
</style>
